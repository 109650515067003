@use "../../style/foundation";

.tour-page {
  .ant-card-body {
    padding: 15px 20px;
    margin-bottom: 20px;
  }

  .tour-step-validation-error-icon {
    margin-left: 10px;

    svg {
      fill: #f00;
    }
  }

  .file-dragger {
    &.file-dragging {
      .ant-upload-drag {
        background-color: #f00;
      }
    }
  }

  .number-box {
    span {
      font-weight: 600;
      color: #8c8c8c;
      font-size: 14px;
    }

    h3 {
      font-weight: 700;
      margin-bottom: 0px;
      font-size: 30px;

      small {
        font-weight: 600;
        font-size: 14px;
        margin-left: 10px;
      }
    }

    .icon-box {
      width: 48px;
      height: 48px;
      text-align: center;
      background: #1890ff;
      color: #fff;
      border-radius: 0.5rem;
      margin-left: auto;
      line-height: 55px;

      span {
        color: #fff;
        font-size: 24px;
      }
    }
  }
}

.page-view-tour-image-toolbar-wrapper {
  position: fixed;
  bottom: 32px;
  left: 50%;
  padding: 0px 24px;
  color: #fff;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  transform: translateX(-50%);
}

.page-view-tour-image-toolbar-wrapper .anticon {
  padding: 12px;
  cursor: pointer;
}

.page-view-tour-image-toolbar-wrapper .anticon[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.page-view-tour-image-toolbar-wrapper .anticon:hover {
  opacity: 0.3;
}
