@use "../../style/foundation";

.layout-admin{
  width: 100%;
  display: flex;
  background-color: foundation.$bg-color-light;

  .ant-header-fixed {
    //background-color: inherit;
    background: #fff;
    box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
    margin: 10px 10px;
    z-index: 10;
    box-shadow: 0px 20px 27px #0000000d;
    border-radius: 12px;
  }

  .ant-layout-header {
    padding-inline: 10px;
  }

  .page-content {
    margin: 10px;

    > h1 {
      margin-bottom: 20px;
    }

    .table-responsive {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  
    .criclebox {
      box-shadow: 0px 20px 27px #0000000d;
      border-radius: 12px;
    }
  
    .criclebox table th.ant-table-cell {
      background-color: inherit;
      text-transform: uppercase;
      color: #666;

      &::before {
        display: none;
      }
    }
  
    .ant-table-tbody tr:last-child {
      td {
        border-bottom: none;
      }
    }  
  }
}
