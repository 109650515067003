$bg-color-light: #f5f5f5;
$bg-color-dark: #111;

$h1-color-light: rgb(188, 188, 188);
$h1-color-dark: rgb(110, 110, 110);

$h1-subheader-color-light: $h1-color-light;
$h1-subheader-color-dark: $h1-color-dark;

$responsive-mobile-width: 992px;


@mixin block-with-shadow($radius: 0px) {
  padding: 20px;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

  @if $radius != 0px {
    border-radius: $radius;
  }
}

@mixin h1-header {
  color: $h1-color-light;
  font-size: 30px;
}

@mixin h1-subheader {
  color: $h1-color-light;
  font-size: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 10px;
}
