@use "../../style/foundation";

.ant-drawer-body {
  padding: 0px !important;
}

.ant-layout-sider {
  .ant-layout-sider-children {
    width: auto;
    background-color: foundation.$bg-color-light;
  }
 }

.sidebar{
  background-color: foundation.$bg-color-light;
  width: 250px;
  height: 100vh;
  overflow: auto;
  margin: 0;
  padding: 20px;

  .top{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo{
      font-size: 20px;
      font-weight: bold;
      color: purple;
    }
  }

  hr{
    margin: 18px 0;
    border: none;
    height: 1px;
    background-color: #f5f5f5;
  }
 
  .ant-menu.sidebar-menu {
    background: transparent;
    border: none;

    .menu-item {
      margin: 0;
      overflow: visible;
      padding: 0 !important;
      height: auto;
      line-height: normal;

      .ant-menu-title-content {
        margin: 0px;
      }

      .menu-item-icon {
        display: inline-flex;
        width: 32px;
        height: 32px;
        background-color: #fff;
        box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
        border-radius: 6px;
        justify-content: center;
        align-items: center;
        margin: 11px;
        vertical-align: middle;

        svg {
          fill: #999;
        }
      }

      &:has(a.active) {
        background-color: #fff;
        box-shadow: 0 20px 27px rgb(0 0 0 / 5%);
        font-weight: 600;    

        .menu-item-icon {
          background-color: #1890ff;

          svg {
            fill: #fff;
          }
        }
      }
        
      &.ant-menu-item-active {
        background-color: inherit;
      }

      a {
        padding: 10px;
        color: #141414;
        border-radius: 8px;
        display: flex;
        align-items: center;
        white-space: nowrap;
      }
    }

  }

  .bottom{
    display: flex;
    align-items: center;
    margin: 10px;

    .colorOption{
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #7451f8;
      cursor: pointer;
      margin: 5px;

      &:nth-child(1){
        background-color: whitesmoke;
      }
      &:nth-child(2){
        background-color: #333;
      }
    }
  }
}
