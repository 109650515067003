@use "../../style/foundation";

.navbar{
  .header-control {
    @media (min-width: foundation.$responsive-mobile-width) {
      .sidebar-menu-button {
        display: none !important;
      }
    }

    button svg {
      width: 20px;
      height: 20px;
    }
  }

  .right-cell {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;
  }
}
