.homePage{
  .widgets, .charts{
    display: flex;
    padding: 20px;
    gap: 20px;
  }

  .charts{
    padding: 5px 20px;
  }

  .listContainer{
    padding: 20px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    margin: 20px;

    .listTitle{
      font-weight: 500;
      color: gray;
      margin-bottom: 50px;
    }
  }
}
